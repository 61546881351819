import React from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  HorizontalDivider,
  RecruitmentWrapper,
  StageImageWrapper,
  StageWrapper,
  StageNumberWrapper,
  DescriptionItem,
  DescriptionText,
  StageNumber,
  ArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowBottom from "-!svg-react-loader!../../../../../../assets/images/arrowDown.svg";
import RecruitmentFirstStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment1.svg";
import RecruitmentSecondStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment2.svg";
import RecruitmentThirdStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment3.svg";
import RecruitmentFourthStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment4.svg";
import RecruitmentFifthStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment5.svg";
import { PageTitle } from "../../../../../common/PageTitle";

interface RecruitmentProps {
  pageChange: PageChange;
}

const InternshipsMobile: React.FC<RecruitmentProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`recruitment_title`))} />
      <PageWrapper animation={pageChange}>
        <Title>{fixConjunctions(t(`internships_title`))}</Title>
        <Description>
          {fixConjunctions(t(`recruitment_description`))}
        </Description>
        <HorizontalDivider />
        <RecruitmentWrapper>
          <StageWrapper>
            <StageImageWrapper>
              <RecruitmentFirstStageIcon width="68px" height="64px" />
              <ArrowWrapper>
                <ArrowBottom height="44px" />
              </ArrowWrapper>
            </StageImageWrapper>
            <StageNumberWrapper>
              <StageNumber>01</StageNumber>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_firstStep`)),
              }}
              noDescription
            />
          </StageWrapper>

          <StageWrapper>
            <StageImageWrapper>
              <RecruitmentSecondStageIcon width="68px" height="64px" />
              <ArrowWrapper>
                <ArrowBottom height="44px" />
              </ArrowWrapper>
            </StageImageWrapper>
            <StageNumberWrapper>
              <StageNumber>02</StageNumber>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_secondStep`)),
              }}
            />
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_secondStepDescription`)),
              }}
            />
          </StageWrapper>

          <StageWrapper>
            <StageImageWrapper>
              <RecruitmentThirdStageIcon width="68px" height="64px" />
              <ArrowWrapper>
                <ArrowBottom height="44px" />
              </ArrowWrapper>
            </StageImageWrapper>
            <StageNumberWrapper>
              <StageNumber>03</StageNumber>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_thirdStep`)),
              }}
            />
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_thirdStepDescription`)),
              }}
            />
          </StageWrapper>

          <StageWrapper>
            <StageImageWrapper>
              <RecruitmentFourthStageIcon width="68px" height="64px" />
              <ArrowWrapper>
                <ArrowBottom height="44px" />
              </ArrowWrapper>
            </StageImageWrapper>
            <StageNumberWrapper>
              <StageNumber>04</StageNumber>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_fourthStep`)),
              }}
              noDescription
            />
          </StageWrapper>

          <StageWrapper>
            <StageImageWrapper>
              <RecruitmentFifthStageIcon width="68px" height="64px" />
            </StageImageWrapper>
            <StageNumberWrapper>
              <StageNumber>05</StageNumber>
            </StageNumberWrapper>
            <DescriptionItem
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_fifthStep`)),
              }}
            />
            <DescriptionText
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(t(`internships_fifthStepDescription`)),
              }}
            />
          </StageWrapper>
        </RecruitmentWrapper>
      </PageWrapper>
    </>
  );
};

export default InternshipsMobile;
